import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
// ../p-defer/index.js
function pDefer() {
  var deferred = {};
  deferred.promise = new _Promise(function (resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred;
}

// src/index.mjs
var src_default = pDefer;
export { src_default as default };
